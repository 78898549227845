export interface FirebaseConfig {
  apiKey: string,
  authDomain: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
}

export const FB_CONFIG: FirebaseConfig = {
  apiKey: process.env["REACT_APP_FB_API_KEY"] || "",
  authDomain: process.env["REACT_APP_FB_AUTH_DOMAIN"] || "",
  projectId: process.env["REACT_APP_FB_PROJ_ID"] || "",
  storageBucket: process.env["REACT_APP_FB_STORAGE_BUCKET"] || "",
  messagingSenderId: process.env["REACT_APP_FB_SENDER_ID"] || "",
  appId: process.env["REACT_APP_FB_APP_ID"] || "",
};

if (
  FB_CONFIG.apiKey.length <= 0
  || FB_CONFIG.authDomain.length <= 0
  || FB_CONFIG.projectId.length <= 0
  || FB_CONFIG.storageBucket.length <= 0
  || FB_CONFIG.messagingSenderId.length <= 0
  || FB_CONFIG.appId.length <= 0
) {
  throw new Error("Please set Firebase configurations in environment");
}