import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom";
import './App.css';
import {
  surveys,
  RenderSurvey,
  getLocalLatestKeys,
  SurveyKey,
} from "./render-survey";
import { FirebaseContextProvider } from "./firebase";
import { v4 as uuid } from "uuid";


function NoSurvey(): JSX.Element {
  return (
    <div className="no-survey">No survey selected</div>
  );
}

const allSurveys = Object.keys(surveys);

function RenderApp({
  userId,
}: {
  userId: string, 
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const surveyKeyFromUrl = searchParams.get("survey");
  const surveyIdFromUrl = searchParams.get("id");
  const readOnly = searchParams.get("read")?.toLowerCase() === "true";

  const {
    surveyKey,
    surveyId: localLatestId,
  } = useMemo(() => getLocalLatestKeys({
    surveyKey: surveyKeyFromUrl as SurveyKey | undefined | null,
    surveyId: surveyIdFromUrl,
  }), [surveyIdFromUrl, surveyKeyFromUrl]);

  const surveyId = useMemo(() => (
    localLatestId || `${surveyKey}-${uuid()}`
  ), [surveyKey, localLatestId]);

  useEffect(() => {
    if (surveyKey && (
      !surveyKeyFromUrl
      || (!surveyIdFromUrl && surveyId)
    )) {
      setSearchParams((prev) => {
        prev.set("survey", surveyKey);
        prev.set("id", surveyId);
        return prev;
      });
    }
  }, [setSearchParams, surveyKey, surveyKeyFromUrl, surveyId, surveyIdFromUrl]);

  const ChosenSurvey = useMemo(() => {
    if (!surveyKey || !surveyId) {
      return NoSurvey;
    }
    if (allSurveys.includes(surveyKey)) {
      return (() => (
        <RenderSurvey
          surveyKey={surveyKey as SurveyKey}
          surveyId={surveyId}
          readOnly={readOnly}
          userId={userId}
        />
      ));
    }
    return NoSurvey;
  }, [surveyKey, readOnly, surveyId, userId]);

  return (
    <ChosenSurvey />
  );
}

function App() {
  const userId = useMemo(() => uuid(), []);
  return (
    <FirebaseContextProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RenderApp userId={userId} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </FirebaseContextProvider>
  );
}

export default App;
