import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ITheme, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import letsTalk from "./surveys/lets-talk.json";
import surveyTheme from "./surveythemes/purple.json";
import { serverTimestamp } from "firebase/firestore"
import { FirebaseTimestamp, compareTimestamps, useFirebaseSurvey } from "./firebase";
import _ from "lodash";

import "survey-core/defaultV2.min.css";

export const surveys = {
  letsTalk: letsTalk,
};
export type SurveyKey = keyof typeof surveys;
export type SurveyData = Model["data"];

const localLatestSurveyKey = {
  get: (): SurveyKey | null => (
    window.localStorage.getItem("survey-cur") as SurveyKey
  ),
  set: (surveyKey: SurveyKey) => {
    window.localStorage.setItem("survey-cur", surveyKey);
  }
}

const localLatestSurveyId = {
  get: (surveyKey: SurveyKey) => (
    window.localStorage.getItem(`survey-cur-${surveyKey}`)
  ),
  set: (surveyKey: SurveyKey, surveyId: string) => {
    window.localStorage.setItem(`survey-cur-${surveyKey}`, surveyId);
  },
}

export const localSurveyData = {
  get: (surveyId: string) => {
    const surveyData = window.localStorage.getItem(`survey-${surveyId}`);
    if (!surveyData) {
      return null;
    }
    try {
      return JSON.parse(surveyData);
    } catch {
      return null;
    }
  },
  set: (surveyId: string, surveyData: SurveyData) => {
    const stringified = JSON.stringify(surveyData);
    window.localStorage.setItem(`survey-${surveyId}`, stringified);
  },
}

export function getLocalLatestKeys({
  surveyKey,
  surveyId,
}: {
  surveyKey?: SurveyKey | null,
  surveyId?: string | null,
}): {
  surveyKey: SurveyKey | null,
  surveyId: string | null,
} {
  const latestSurveyKey = localLatestSurveyKey.get();
  const latestSurveyId = (latestSurveyKey)
    ? localLatestSurveyId.get(latestSurveyKey)
    : null;
  return {
    surveyKey: surveyKey || latestSurveyKey,
    surveyId: surveyId || latestSurveyId,
  };
}

export function formatSurveyDataToSave(survey: Model, {
  surveyKey,
  forFirebase,
  userId,
}: {
  surveyKey: string,
  forFirebase: boolean,
  userId: string,
}) {
  const data = survey.data;
  data.pageNo = survey.currentPageNo;
  data.surveyKey = surveyKey;
  data.userId = userId;
  if (forFirebase) {
    data.timestamp = serverTimestamp();
  } else {
    data.timestamp = Date.now();
  }
  return data;
}

function saveLocalSurveyData(
  surveyKey: SurveyKey,
  surveyId: string,
  _survey: Model,
) {
  // for now we're just saving the ID of the survey the user was last working on
  // and letting firebase handle the rest. Eventually we could theoretically save
  // and grab it from local as a backup, but no real point for now
  localLatestSurveyKey.set(surveyKey);
  localLatestSurveyId.set(surveyKey, surveyId);
}

function clearSurveyData(surveyKey: SurveyKey) {
  window.localStorage.removeItem(`survey-${surveyKey}`);
}

function useSurvey({
  surveyKey,
  surveyId,
  onComplete,
  onUpdate,
  surveyData,
  userId,
  readOnly = false,
  reset = false,
}: {
  surveyKey: SurveyKey,
  surveyId: string,
  onComplete?: (survey: Model) => void,
  onUpdate?: (survey: Model) => void,
  userId: string,
  readOnly?: boolean,
  surveyData?: SurveyData,
  reset: boolean,
}): Model {
  const [passedSurveyData, setPassedSurveyData] = useState(surveyData);
  const survey = useMemo(
    () => {
      if (reset) {
        clearSurveyData(surveyKey);
      }
      const handleChange = (survey: Model) => {
        onUpdate?.(survey);
        saveLocalSurveyData(surveyKey, surveyId, survey);
      };

      let survey = new Model(surveys[surveyKey]);
      if (readOnly) {
        survey.mode = "display";
      }
      survey.applyTheme(surveyTheme as ITheme);
      survey.onValueChanged.add(handleChange);
      survey.onCurrentPageChanged.add(handleChange);
      return survey;
    },
    [surveyKey, reset, readOnly, onUpdate, surveyId],
  );

  // Whether we have seen an initial page load
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    if (passedSurveyData && !hasLoadedRef.current) {
      hasLoadedRef.current = true;
      survey.data = passedSurveyData;
      if (passedSurveyData.pageNo) {
        survey.currentPageNo = passedSurveyData.pageNo;
      }
    }
  }, [passedSurveyData, survey, hasLoadedRef]);

  useEffect(() => {
    if (!surveyData) {
      return;
    }
    if (_.isEqual(surveyData, passedSurveyData)) {
      // no changes to record
      return;
    }
    const oldTimestamp: FirebaseTimestamp = passedSurveyData?.timestamp;
    const newTimestamp: FirebaseTimestamp = surveyData?.timestamp;
    const newUid = surveyData?.userId;

    if (newUid === userId) {
      // this was probably us, so we shouldn't try to re-render our survey data
      setPassedSurveyData(surveyData);
      return;
    }

    if (
      !oldTimestamp
      || compareTimestamps(oldTimestamp, newTimestamp) < 0
    ) {
      survey.data = surveyData;
      setPassedSurveyData(surveyData);
    }
  }, [survey, surveyData, passedSurveyData, userId]);

  useEffect(() => {
    if (onComplete) {
      survey.onComplete.add(onComplete);
    }

    return () => {
      if (onComplete) {
        survey.onComplete.remove(onComplete);
      }
    };
  }, [survey, onComplete]);

  return survey;
}

/**
 * Without a custom memoization this will re-render the survey on every rerender, meaning
 * that any text fields might get overwritten.
 */
const MemoSurvey: React.FC<{ model: Model }> = React.memo(
  ({ model }) => (
    <Survey model={model} />
  ),
  ({ model: oldModel }, { model: newModel }) => (
    oldModel === newModel 
  ),
);

export function RenderSurvey({
  surveyKey,
  surveyId,
  onComplete,
  userId,
  readOnly = false,
  reset = false,
}: {
  surveyKey: SurveyKey,
  surveyId: string,
  onComplete?: (survey: Model) => void,
  userId: string,
  readOnly?: boolean,
  reset?: boolean,
}): JSX.Element {
  const [
    {
      surveyKey: fbSurveyKey,
      data: fbData,
    },
    saveData,
  ] = useFirebaseSurvey(surveyKey, surveyId, userId);
  const handleComplete = useCallback((survey: Model) => {
    saveData(survey);
    onComplete?.(survey);
  }, [onComplete, saveData]);
  const survey = useSurvey({
    surveyKey: fbSurveyKey || surveyKey,
    surveyId,
    readOnly,
    onComplete: handleComplete,
    onUpdate: saveData,
    userId,
    reset,
    surveyData: fbData
  });

  return (
    <MemoSurvey model={survey} />
  );
}