import React, { useMemo, createContext, useContext, useCallback, useState, useEffect } from "react";
// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, Firestore, onSnapshot, DocumentData, DocumentReference } from "firebase/firestore";

import {
  FB_CONFIG,
} from "./config";
import { Model } from "survey-core";
import { SurveyData, SurveyKey, formatSurveyDataToSave } from "./render-survey";
import _ from "lodash";

export type FirebaseTimestamp = {
  seconds: number,
  nanoseconds: number,
};

export function compareTimestamps(left: FirebaseTimestamp, right: FirebaseTimestamp): -1 | 0 | 1 {
  if (left.seconds < right.seconds) {
    return -1;
  }
  if (left.seconds > right.seconds) {
    return 1;
  }
  if (left.nanoseconds < right.nanoseconds) {
    return -1;
  }
  if (left.nanoseconds > right.nanoseconds) {
    return 1;
  }
  return 0;
}

export function useNewFirebaseApp(): FirebaseContextValue {
  // Initialize Firebase
  const app = useMemo(() => (
    initializeApp(FB_CONFIG)
  ), []);

  return {
    getFirebaseApp: () => app,
  };
}

export function useFirebaseApp(): FirebaseApp {
  const { getFirebaseApp } = useContext(FirebaseContext);
  return getFirebaseApp();
}

type SurveyDocRef = DocumentReference<DocumentData, DocumentData>;
export function getSurveyDoc(db: Firestore, surveyId: string) {
  return doc(db, "surveys", surveyId);
}

export async function loadFirebaseSurvey(docRef: SurveyDocRef): Promise<SurveyData | null> {
  const foundSurvey = await getDoc(docRef)
  if (foundSurvey.exists()) {
    return foundSurvey.data() as SurveyData;
  } else {
    return null;
  }
}

async function saveFirebaseSurvey(docRef: SurveyDocRef, data: SurveyData) {
  await setDoc(docRef, data);
}

export function useSaveToFirebase(
  surveyKey: string,
  docRef: SurveyDocRef,
  userId: string,
): (survey: Model) => void {
  const callback = useCallback(async (survey: Model) => {
    const saveData = formatSurveyDataToSave(survey, {
      surveyKey,
      forFirebase: true,
      userId,
    });
    await saveFirebaseSurvey(docRef, saveData);
  }, [surveyKey, docRef, userId]);
  return callback;
}

type FirebaseSurvey = {
  surveyKey?: SurveyKey,
  data?: SurveyData,
};

export function useFirebaseSurvey(
  surveyKey: SurveyKey,
  surveyId: string,
  userId: string,
): [FirebaseSurvey, (survey: Model) => void] {
  const app = useFirebaseApp();
  const db = useMemo(() => getFirestore(app), [app]);
  const doc = useMemo(() => getSurveyDoc(db, surveyId), [db, surveyId]);

  const [surveyData, setSurveyData] = useState<SurveyData>(null);
  const saveToFirebase = useSaveToFirebase(surveyKey, doc, userId);

  // connect to the realtime firebase store callback
  useEffect(() => {
    const unsub = onSnapshot(doc, (document) => {
      if (!document.exists()) {
        return;
      }
      const sentData = document.data();
      setSurveyData((prev: DocumentData) => {
        if(_.isEqual(sentData, prev)) {
          return prev;
        }
        return sentData;
      });
    });
    return unsub;
  }, [doc, setSurveyData]);

  const survey = useMemo(() => ({
    data: surveyData || undefined,
    surveyKey: surveyData?.surveyKey,
  }), [surveyData]);

  return [survey, saveToFirebase];
}

interface FirebaseContextValue {
  getFirebaseApp: () => FirebaseApp,
}

const FirebaseContext = createContext<FirebaseContextValue>({
  getFirebaseApp: () => { throw new Error("Firebase App not provided"); },
});

export function FirebaseContextProvider({
  children,
}: React.PropsWithChildren): JSX.Element {
  const context = useNewFirebaseApp();

  return (
    <FirebaseContext.Provider value={context}>
      { children }
    </FirebaseContext.Provider>
  );
}